<template>
  <LabelledContent v-bind="$attrs">
    <v-container
      fluid
      class="pa-1"
    >
      <v-row dense>
        <v-col cols="12">
          <v-menu
            v-model="showDateSelection"
            :close-on-content-click="true"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{on, attr}">
              <v-text-field
                readonly
                :label="$t('date')"
                filled
                v-model="date"
                v-bind="attr"
                v-on="on"
                hide-details
              />
            </template>
            <v-date-picker
              @input="setDate"
              no-title
              style="min-height: 350px"
            />
          </v-menu>
        </v-col>
        <v-col cols="12">
          <v-menu
            v-model="showTimeSelection"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                large
                filled
                v-model="time"
                :label="$t('time')"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <TimeSelector
              v-if="showTimeSelection"
              v-model="showTimeSelection"
              @selectTime="setTime"
              no-title
              :time="time"
              @close="showTimeSelection = false"
            />
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </LabelledContent>
</template>

<script> 
import TimeSelector from "@/components/common/TimeSelector.vue"
import LabelledContent from "@/components/common/structure/LabelledContent"

export default {
    name: 'PeriodSelector', 
    components: {
      LabelledContent,
      TimeSelector
    },
    props: {
        timeInMillis: {
            type: Number, 
            default: null
        }
    }, 
    data(){ 
      return {
        showDateSelection: false,
        showTimeSelection: false
      }
    },
    computed: {
      date() {
        if(!this.timeInMillis) {
          return null
        }
        return new Date(this.timeInMillis).toISOString().split('T')[0]
      },
      time() {
        if(!this.timeInMillis) {
          return null
        }

        const timestamp = new Date(this.timeInMillis)
        const hours = timestamp.getHours()
        const minutes = timestamp.getMinutes()

        return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0')
      }
    },
    methods: {
      setTime(time) {
        const splitStartTime = time.split(':')
        const hours = Number(splitStartTime[0])
        const minutes = Number(splitStartTime[1])
        let seconds = 0
        let milliseconds = 0
        const newStartInMillis = new Date(this.timeInMillis).setHours(hours, minutes, seconds, milliseconds)
        this.$emit('updateTimeInMillis', newStartInMillis)
      },
      setDate(date) {
        const hours = new Date(this.timeInMillis).getHours()
        const minutes = new Date(this.timeInMillis).getMinutes()
        let seconds = 0
        let milliseconds = 0
        const newStartInMillis = new Date(date).setHours(hours, minutes, seconds, milliseconds)
        this.$emit('updateTimeInMillis', newStartInMillis)
      }
    }
}
</script>