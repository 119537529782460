<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <PeriodSelector
          :label="$t('from')"
          :time-in-millis="seasonalPrice.startInMillis"
          @updateTimeInMillis="updateStartInMillis"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <PeriodSelector
          :label="$t('to')"
          :time-in-millis="seasonalPrice.endInMillis"
          @updateTimeInMillis="updateEndInMillis"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <SimplePrice
          filled
          hide-details
          :label="$t('price')"
          :currency-code="currencyCode"
          v-model="price"
          @updateCurrency="updateCurrency"
        />
      </v-col>
      <v-col
        cols="12"
        class="text-end pt-0 pb-0"
      >
        <v-btn
          text
          color="error"
          @click="removeSeasonalPrice"
        >
          {{ $t('remove') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SimplePrice from "@/components/common/pricing/SimplePrice"
import PeriodSelector from "@/components/common/PeriodSelector"

export default {
  name: "SeasonalPrice",
  components: {
    PeriodSelector,
    SimplePrice
  },
  props: {
    seasonalPrice: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    price: {
      get() {
        if(typeof this.seasonalPrice !== 'undefined' && this.seasonalPrice !== null) {
          if(typeof this.seasonalPrice.price !== 'undefined' && this.seasonalPrice.price !== null) {
            if(typeof this.seasonalPrice.price.amount !== 'undefined' && this.seasonalPrice.price.amount !== null) {
              return this.seasonalPrice.price.amount
            }
          }
        }
        return null
      },
      set(value) {
        const newSeasonalPrice = this.seasonalPrice
        newSeasonalPrice.price.amount = value
        this.updateSeasonalPrice(newSeasonalPrice)
      }
    },
    currencyCode() {
      if(typeof this.seasonalPrice !== 'undefined' && this.seasonalPrice !== null) {
        if(typeof this.seasonalPrice.price !== 'undefined' && this.seasonalPrice.price !== null) {
          if(typeof this.seasonalPrice.price.currency_code !== 'undefined' && this.seasonalPrice.price.currency_code !== null) {
            return this.seasonalPrice.price.currency_code
          }
        }
      }
      return ''
    }
  },
  methods: {
    removeSeasonalPrice() {
      this.$emit('removeSeasonalPrice', this.index)
    },
    updateAmount(amount) {
      const newSeasonalPrice = this.seasonalPrice
      newSeasonalPrice.price.amount = amount
      this.updateSeasonalPrice(newSeasonalPrice)
    },
    updateCurrency(currencyCode) {
      const newSeasonalPrice = this.seasonalPrice
      newSeasonalPrice.price.currency_code = currencyCode
      this.updateSeasonalPrice(newSeasonalPrice)
    },
    updateStartInMillis(startInMillis) {
      const newSeasonalPrice = this.seasonalPrice
      newSeasonalPrice.startInMillis = startInMillis
      this.updateSeasonalPrice(newSeasonalPrice)
    }, 
    updateEndInMillis(endInMillis) {
      const newSeasonalPrice = this.seasonalPrice
      newSeasonalPrice.endInMillis = endInMillis
      this.updateSeasonalPrice(newSeasonalPrice)
    },
    updateSeasonalPrice(value) {
      this.$emit('updateSeasonalPrice', { value: value , index: this.index })
    }
  }
}
</script>

<style scoped>

</style>