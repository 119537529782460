<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row>
      <template v-for="(seasonalPrice, index) in seasonalPrices">
        <v-col
          :key="'sp-' + index"
          cols="12"
        >
          <SeasonalPrice
            :seasonal-price="seasonalPrice"
            :index="index"
            v-on="$listeners"
          />
          <v-divider />
        </v-col>
      </template>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn
          text
          @click="addNewSeasonalPrice"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('addNewSeasonalPrice') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SeasonalPrice from "@/components/common/pricing/SeasonalPrice"

export default {
  name: "SeasonalPrices",
  components: {
    SeasonalPrice
  },
  props: {
    seasonalPrices: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    addNewSeasonalPrice() {
      this.$emit('addNewSeasonalPrice')
    }
  }
}
</script>

<style scoped>

</style>