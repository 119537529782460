<template>
  <v-card
    outlined
    class="pa-3"
  >
    <div
      class="text--secondary white px-1"
      style="left: 12px; position: absolute; top: -10px; font-size: 12px;"
    >
      {{ label }}
    </div>
    <slot name="default" />
  </v-card>
</template>

<script>
export default {
  name: "LabelledContent",
  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>